<template>
  <b-sidebar
    id="add-new-role-sidebar"
    :visible="isAddNewRoleSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-role-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Add Role</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->

          <validation-provider
            #default="validationContext"
            name="Role Name"
            rules="required"
          >
            <b-form-group label="Role Name" label-for="Role Name">
              <b-form-input id="Name" v-model="roledata.name" autofocus trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        

          <div
            class="d-flex bg-light text-light align-items px-3 py-2"
            style="position: absolute; bottom: 0%; left: 0%; width: 100%"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              :disabled="addRole"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              style="margin-left: 10px"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BCol,
  BFormFile,
  BFormSelect,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "../../../../@fake-db/data/other/countries";
import store from "@/store";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BSidebar,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormFile,
    BInputGroupAppend,
    ToastificationContent,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewRoleSidebarActive",
    event: "update:is-add-new-role-sidebar-active",
  },
  props: {
    isAddNewRoleSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
//   mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      roledata: {
        name: "",
      },
      add_new_role_data: "",
    };
  },
  computed: {
    addRole() {
      if (this.roledata.name.length > 3) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.roledata = {
        name: "",
        
      };
    },
    onSubmit() {
      // console.log(this.roledata);
      this.add_new_role_data = this.roledata;
      this.$emit("clickaddNewRole", this.add_new_role_data);
      this.$root.$emit("bv::toggle::collapse", "add-new-role-sidebar");
    //   if (this.roledata.password == this.roledata.password_confirmation) {
    //     this.add_new_user_data = this.roledata;
    //     this.$emit("clickaddNewUser", this.add_new_user_data);
    //     this.$root.$emit("bv::toggle::collapse", "add-new-users-sidebar");
    //   } else {
    //     this.showToast("danger", "top-left");
    //     // alert("Please confirm your password first");
    //   }

      //   console.log(this.add_new_level_data);
    },
    showToast(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "Please confirm your password first",
            variant,
          },
        },
        {
          position,
        }
      );
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
